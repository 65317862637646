import React from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import { visuallyHidden } from "@mui/utils";
import TableRow from "@mui/material/TableRow";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableContainer from "@mui/material/TableContainer";
import TableSortLabel from "@mui/material/TableSortLabel";
import TablePagination from "@mui/material/TablePagination";
import { orderServices } from "../../../../../../services/orderServices";
import EditOrder from "./components/EditOrder";
import { Button } from "@mui/material";
import { useDispatch } from "react-redux";
import { getUserOrders } from "../../../../../../redux/orders/orderSlice";
import { userServices } from "../../../../../../services/userServices";
import { getUser } from "../../../../../../redux/users/userSlice";

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort(array, comparator) {
  const stabilizedThis = array?.map((el, index) => [el, index]);
  stabilizedThis?.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis?.map((el) => el[0]);
}

const headCells = [
  {
    id: "id",
    disablePadding: false,
    label: "Id",
  },
  {
    id: "createdAt",
    disablePadding: false,
    label: "Created",
  },
  {
    id: "closedAt",
    disablePadding: false,
    label: "Closed",
  },
  {
    id: "openPrice",
    disablePadding: false,
    label: "Open Price",
  },
  {
    id: "closedPrice",
    disablePadding: false,
    label: "Closed Price",
  },
  {
    id: "stopLoss",
    disablePadding: false,
    label: "Stop Loss",
  },
  {
    id: "takeProfit",
    disablePadding: false,
    label: "Take Profit",
  },
  {
    id: "assetType",
    right: false,
    disablePadding: false,
    label: "Asset",
  },
  {
    id: "quantity",
    disablePadding: false,
    label: "Quantity",
  },
  {
    id: "leverage",
    right: false,
    disablePadding: false,
    label: "Leverage",
  },
  {
    id: "type",
    disablePadding: false,
    label: "Type",
  },
  {
    id: "profit",
    disablePadding: false,
    label: "Profit",
  },
  {
    id: "buyOrSell",
    disablePadding: false,
    label: "Buy Or Sell",
  },
  {
    id: "investmentAmount",
    disablePadding: false,
    label: "Investment Amount",
  },
  {
    id: "userId",
    disablePadding: false,
    label: "User ID",
  },
  {
    id: "actions",
    disablePadding: false,
    label: "Actions",
  },
];

function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead style={{ background: "#3E4396" }}>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align="center"
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

export default function EnhancedTable({
  user,
  setEditButtonOptions,
  userDetails,
  setUserDetails,
}) {
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("");
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [openDeleteOrderModal, setOpenDeleteOrderModal] = React.useState(false);
  const [orderToEdit, setOrderToEdit] = React.useState(null);
  const [openModal, setOpenModal] = React.useState(false);
  const [orderId, setOrderId] = React.useState(null);
  const [rows, setRows] = React.useState([]);
  const [orderAmount, setOrderAmount] = React.useState(null);
  const dispatch = useDispatch();
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleClick = (event, order) => {
    setOrderToEdit(order);
    setOpenModal(true);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  React.useEffect(async () => {
    console.log("INSIDE");

    await orderServices.getUserOrderHistory(user.id).then((data) => {
      console.log(data);

      setRows(data);
    });
  }, [user.id]);

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows?.length) : 0;

  return (
    <>
      {rows?.length > 0 ? (
        <Box sx={{ width: "100%" }}>
          <Paper sx={{ width: "100%", mb: 2 }}>
            <TableContainer
              sx={{
                "&::-webkit-scrollbar": {
                  height: "5px",
                },
                "&::-webkit-scrollbar-track": {
                  backgroundColor: "#f1f1f1",
                },
                "&::-webkit-scrollbar-thumb": {
                  backgroundColor: "#888",
                },
              }}
            >
              <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle">
                <EnhancedTableHead
                  order={order}
                  orderBy={orderBy}
                  onRequestSort={handleRequestSort}
                  rowCount={rows?.length}
                />
                <TableBody style={{ background: "#1F2A40 " }}>
                  {/* if you don't need to support IE11, you can replace the `stableSort` call with:
                 rows.slice().sort(getComparator(order, orderBy)) */}

                  {stableSort(rows, getComparator(order, orderBy))
                    ?.slice(
                      page * rowsPerPage,
                      page * rowsPerPage + rowsPerPage
                    )
                    .map((row, index) => {
                      return (
                        <TableRow
                          hover={true}
                          onDoubleClick={(event) => handleClick(event, row)}
                          role="checkbox"
                          tabIndex={-1}
                          key={row?.id}
                        >
                          <TableCell align="center">{row.id}</TableCell>
                          <TableCell align="center">
                            {row.createdAt &&
                              new Date(+row.createdAt).toLocaleString("sv-SE", {
                                timeZone: "Europe/Stockholm",
                              })}
                          </TableCell>
                          <TableCell align="center">
                            {row.closedAt &&
                              new Date(+row.closedAt).toLocaleString("sv-SE", {
                                timeZone: "Europe/Stockholm",
                              })}
                          </TableCell>
                          <TableCell align="center">{row.openPrice}</TableCell>
                          <TableCell align="center">
                            {row.closedPrice}
                          </TableCell>
                          <TableCell align="center">{row.stopLoss}</TableCell>
                          <TableCell align="center">{row.takeProfit}</TableCell>
                          <TableCell align="center">{row.assetType}</TableCell>
                          <TableCell align="center">{row.quantity}</TableCell>
                          <TableCell align="center">{row.leverage}</TableCell>
                          <TableCell align="center">{row.type}</TableCell>
                          <TableCell align="center">{row.profit}</TableCell>
                          <TableCell align="center">{row.buyOrSell}</TableCell>
                          <TableCell align="center">
                            {row.investmentAmount}
                          </TableCell>
                          <TableCell align="center">{row.userId}</TableCell>
                          <TableCell align="center">
                            <Button
                              onClick={() => {
                                setOrderId(row.id);
                                setOrderAmount(row.profit);
                                setOpenDeleteOrderModal(true);
                              }}
                              color="error"
                            >
                              DELETE
                            </Button>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  {emptyRows > 0 && (
                    <TableRow
                      style={{
                        height: 53 * emptyRows,
                      }}
                    >
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              style={{ background: "#3E4396" }}
              count={rows?.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Paper>
          {openModal && (
            <EditOrder
              order={orderToEdit}
              setOpenModal={setOpenModal}
              userId={user?.id}
            />
          )}
          {openDeleteOrderModal && (
            <div
              className="areYouSureModal"
              style={{
                width: "35%",
                height: "35%",
                inset: 0,
                margin: "20% auto",
              }}
            >
              <h2 style={{ margin: "1rem auto" }}>Are you sure?</h2>
              <h3>The order will be permanently terminated!</h3>
              <div className="areYouSureModalBtnContainer">
                <Button
                  variant="contained"
                  color="success"
                  style={{ height: "50px", width: "100px", marginTop: "20%" }}
                  onClick={async () => {
                    await orderServices.deleteOrder(orderId).then(async () => {
                      dispatch(getUserOrders(user.id));
                      setOpenDeleteOrderModal(false);
                      setEditButtonOptions("");
                      await userServices
                        .changeUserBallance(user.id, {
                          ballance: (
                            Number(user.ballance) - Number(orderAmount)
                          )
                            .toFixed(2)
                            .toString(),
                        })
                        .then(() => {
                          setUserDetails({
                            ...userDetails,
                            ballance: (
                              Number(user.ballance) - Number(orderAmount)
                            )
                              .toFixed(2)
                              .toString(),
                          });
                        });
                    });

                    // .then(() => {
                    //   dispatch(getUser(user.id));
                    // });
                  }}
                >
                  YES
                </Button>
                <Button
                  style={{ height: "50px", width: "100px", marginTop: "20%" }}
                  variant="contained"
                  color="error"
                  onClick={() => setOpenDeleteOrderModal(false)}
                >
                  NO
                </Button>
              </div>
            </div>
          )}
        </Box>
      ) : (
        <h3 style={{ color: "red" }}>No available orders...</h3>
      )}
    </>
  );
}
